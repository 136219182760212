import React, { useEffect, useRef, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, Grid, TextField, Paper, Tabs, Tab, CircularProgress, InputAdornment } from '@material-ui/core';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { ListVideoAdmin, RestoreVideoInTrash } from '../../lib/graphql-command/videos-command';
import { getImage, pluralize } from '../../lib/utils';
import moment from 'moment';
import { Link, navigate } from 'gatsby';
import { Pagination } from '@material-ui/lab';
import { ButtonTemplate } from '../../components/button/button-template';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import noimage from '../../images/noimage.png';
import { DeleteVideoDialog } from '../../components/videos/delete-video-dialog';
import MessageDialog from '../../components/dialog/message-dialog';
import CloseIcon from '@material-ui/icons/Close';
import { GetUser } from '../../lib/graphql-command/user-command';

const useStyle = makeStyles(() => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    date: {
        whiteSpace: 'break-spaces'
    },
    links: {
        display: 'none',
        minHeight: '5px',
        '& a': {
            marginRight: '5px',
            textDecoration: 'none',
            fontSize: '0.9rem'
        }
    },
    trash: {
        color: '#a00'
    },
    row: {
        verticalAlign: 'top',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
    searchBtn: {
        borderRadius: '50px',
        marginLeft: '10px'
    },
    videoTitle: {
        width: '40%'
    },
    image: {
        width: '60px',
        height: '60px',
        objectFit: 'cover'
    },
    link: {
        textDecoration: 'none'
    },
    search: {
        flex: 'auto'
    },
    loading: {
        opacity: '0.5',
        pointerEvents: 'none',
        position: 'relative'
    },
    loadingIcon: {
        opacity: '1',
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    textIcon: {
        cursor: 'pointer'
    },
    tab: {
        minWidth: '150px'
    }
}))

const bucket = "mlnpstagingmediastgstack-invideosbb6a0bcd-ymkje5zlsj2y";

const Videos = () => {
    const classes = useStyle();
    const [videos, setVideos] = useState();
    const [deleteVideo, setDeleteVideo] = useState();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({FilterText: '', FilterDate: null});
    const [filterDate, setFilterDate] = useState(null);
    const [filter, setFilter] = useState(0)
    const [page, setPage] = useState(1)
    const searchRef = useRef();
    const pageSize = 100

    const [user, setUser] = useState(null);

    const filters = [
        {
            count: 'Total',
            value: 0,
            condition: {},
            text: 'All'
        }, {
            count: 'TotalMine',
            value: 1,
            condition: {
                UserID: user?.UserID
            },
            text: 'Mine'
        }, {
            count: 'TotalPublished',
            value: 2,
            condition: {
                Status: 1
            },
            text: 'Published'
        }, {
            count: 'TotalPending',
            value: 3,
            condition: {
                Status: 0
            },
            text: 'Pending'
        }, {
            count: 'TotalDraft',
            value: 4,
            condition: {
                Status: 2
            },
            text: 'Draft'
        }, {
            count: 'TotalTrash',
            value: 5,
            condition: {
                Deleted: 1
            },
            text: 'Trash'
        },
    ]

    useEffect(() => {
        API.graphql(graphqlOperation(GetUser))
        .then(result => setUser(result.data?.getUser))
        .catch(err => console.log(err));
    }, [])

    useEffect(() => {
        getListVideo();
    }, [page, search, filter])

    const getListVideo = () => {
        setLoading(true);
        API.graphql(graphqlOperation(ListVideoAdmin, {
            NumberItem: pageSize,
            Page: page,
            FilterText: search.FilterText,
            FilterDate: search.FilterDate,
            ...filters[filter].condition
        }))
        .then(result => {
            setLoading(false)
            setVideos(result.data?.getVideoListAdmin)
        })
        .catch(err => console.log(err))
    }

    const displayDate = video => {
        const prefix = video.Status === 1 && video.PublishedAt ? `Published` : 'Last Modified'
        return prefix + '\n' + moment(video.Status === 1 && video.PublishedAt ? video.PublishedAt : (video.UpdatedAt || video.CreatedAt)).format('YYYY/MM/DD [at] hh:mm a')
    }

    const handleSearch = () => {
        setPage(1)
        setSearch({
            FilterText: searchRef.current.value || '',
            FilterDate: filterDate ? moment(filterDate).format('MM-yyyy') : null
        })
    }

    const handleChangeTab = (event, newValue) => {
        setPage(1)
        setFilter(newValue)
    }

    const handleDelete = (e, video) => {
        e.preventDefault();
        setDeleteVideo(video)
        setOpenDeleteDialog(true)
  }

    const handleRestore = (e, video) => {
        e.preventDefault();
        API.graphql(graphqlOperation(RestoreVideoInTrash, {VideoID: video.VideoID}))
        .then(result => {
            setMessageDialog('This video has been restored');
            getListVideo();
        })
        .catch(err => console.log(err))
    }

    const pageCount = () => {
        return Math.ceil((videos?.CountAll||0)/pageSize)
    }

    const handleFilterByUser = (username) => {
        searchRef.current.value = username;
        handleSearch();
    }

    return (
        <Box>
            <Grid container>
                <Typography variant='h4'>Videos</Typography>
                <ButtonTemplate
                    borderradius='50px'
                    variant='outlined'
                    color='primary'
                    size='small'
                    margin='0 0 5px 10px'
                    onClick={() => navigate('/new-video')}
                >Add New</ButtonTemplate>
            </Grid>
            <Box py={10}>
                <Grid container spacing={8}>
                    <Grid item>
                        <Paper>
                            <Tabs
                                value={filter}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                size='small'
                            >
                                {filters.map(tab => (
                                    <Tab key={tab.value} label={`${tab.text} (${videos ? videos[tab.count] : 0})`} className={classes.tab}/>
                                ))}
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.search}>
                        <Grid container justify='flex-end' spacing={10}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        placeholder='All dates'
                                        fullWidth
                                        size='small'
                                        views={['month', 'year']}
                                        format='MM/yyyy'
                                        variant='inline'
                                        inputVariant='outlined'
                                        value={filterDate}
                                        InputAdornmentProps={{ position: 'end' }}
                                        onChange={date => setFilterDate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <Box>
                                    <TextField variant='outlined'
                                        placeholder='Author'
                                        size='small'
                                        inputRef={searchRef}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                handleSearch();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.textIcon}
                                                    onClick={e => {
                                                        searchRef.current.value = ''
                                                        handleSearch()
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ButtonTemplate variant='contained'
                                        color='primary'
                                        borderradius='50px'
                                        margin='0 0 0 10px'
                                        onClick={handleSearch}
                                    >Search Videos</ButtonTemplate>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className={loading ? classes.loading : ''}>
                {loading && <Box className={classes.loadingIcon}><CircularProgress size={40} /></Box>}
                {videos?.Items?.length > 0 && <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell className={classes.videoTitle}>Title</TableCell>
                                <TableCell>Author</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {videos?.Items?.map(video => (
                                <TableRow key={video.VideoID} className={classes.row}>
                                    <TableCell>
                                        <img
                                            src={video?.Stills[0]?.key ? getImage(video?.Stills[0]) : noimage}
                                            onError={e => e.target.src = noimage}
                                            className={classes.image}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/videos/${video.VideoID}/edit`} className={classes.link}>{video.VideoTitle}</Link>
                                        {filter !== 5 && <i>{video.Status === 0 ? ' - Pending' : video.Status === 2 ? ' - Draft' : ''}</i>}
                                        {filter === 5 ? <Box className={classes.links}>
                                                <Link to='#' onClick={e => handleRestore(e, video)}>Restore</Link>
                                                <Link to='#' className={classes.trash} onClick={e => handleDelete(e, video)}>Delete Permanently</Link>
                                            </Box>
                                        : <Box className={classes.links}>
                                            <Link to={`/videos/${video.VideoID}/edit`}>Edit</Link>
                                            <Link to='#' className={classes.trash} onClick={e => handleDelete(e, video)}>Trash</Link>
                                            <a
                                                href={`${process.env.GATSBY_CLOUDFRONT_DOMAIN}${video?.VideoReal?.key}`}
                                                download
                                                target='_blank'
                                            >Download</a>
                                            <a
                                                href={`${process.env.GATSBY_CLOUDFRONT_DOMAIN}${video?.VideoIntro?.key}`}
                                                download
                                                target='_blank'
                                            >Download Intro</a>
                                        </Box>}
                                    </TableCell>
                                    <TableCell>
                                        <Link to='#' className={classes.link} onClick={e => handleFilterByUser(video.UserName)}>
                                            {video.UserName}
                                        </Link>
                                    </TableCell>
                                    <TableCell className={classes.date}>{displayDate(video)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
            {videos?.Items?.length <= 0 && <Box>
                No videos found
            </Box>}
            {videos?.CountAll > 0 && <Box py={10}>
                <Grid container>
                    <Grid item>
                        {pluralize(videos?.CountAll, 'video', 'videos')}
                    </Grid>
                    <Grid item>
                        {pageCount() > 1 && <Box pb={10}>
                            <Pagination count={pageCount()} onChange={(e, value) => setPage(value)} page={page} color="primary"/>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}
            <DeleteVideoDialog {...{open: openDeleteDialog, setOpen: setOpenDeleteDialog, video: deleteVideo, status: filter, getListVideo}} />
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
      </Box>
    )
}

export default Videos;