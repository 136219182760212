export const ListVideos = `query MyQuery {
    listVideos {
        FirstName
        LastName
        NickName
        Birthday
        Country
        VideoTitle
        VideoDescription
        VideoFilm
        VideoTags
        VideoDuration
        Status
        UserID
        CreatedAt
        UpdatedAt
        VideoID
        Stills {
            bucket
            region
            key
        }
        VideoReal {
            bucket
            region
            key
        }
    }
}`

export const ListVideoAdmin = `query MyQuery($FilterDate: String, $FilterText: String, $NumberItem: Int, $Page: Int, $Status: Int, $UserID: Int, $Deleted: Int) {
    getVideoListAdmin(input: {FilterDate: $FilterDate, FilterText: $FilterText, NumberItem: $NumberItem, Page: $Page, Status: $Status, UserID: $UserID, Deleted: $Deleted}) {
        Items {
            VideoID
            VideoTitle
            Status
            CreatedAt
            UpdatedAt
            UserName
            PublishedAt
            Stills {
                bucket
                region
                key
                order
            }
            VideoIntro {
                bucket
                region
                key
                order
            }
            VideoReal {
                bucket
                region
                key
                order
            }
        }
        CountAll
        Deleted
        FilterDate
        FilterText
        UserID
        TotalTrash
        TotalPublished
        TotalPending
        TotalMine
        TotalDraft
        Total
        Status
        Page
        NumberItem
    }
}`

export const ListVideoInTrash = `query MyQuery($NumberItem: Int, $Page: Int, $FilterText: String, $FilterDate: String) {
    getVideoListInTrashAdmin(input: {NumberItem: $NumberItem, Page: $Page, FilterText: $FilterText, FilterDate: $FilterDate}) {
        CountAll
        NumberItem
        Page
        FilterDate
        FilterText
        Items {
            VideoID
            VideoTitle
            Status
            CreatedAt
            UpdatedAt
            UserName
            Stills {
            bucket
            region
            key
            order
            }
            VideoIntro {
            bucket
            region
            key
            order
            }
            VideoReal {
            bucket
            region
            key
            order
            }
        }
    }
}`

export const MoveVideoToTrash = `mutation MyMutation($VideoID: Int!) {
    moveVideoToTrashAdmin(VideoID: $VideoID) {
        Message
        Status
    }
}`

export const DeleteVideoInTrash = `mutation MyMutation($VideoID: Int!) {
    deleteVideoInTrashAdmin(VideoID: $VideoID) {
        Message
        Status
    }
}`

export const RestoreVideoInTrash = `mutation MyMutation($VideoID: Int!) {
    restoreVideoInTrashAdmin(VideoID: $VideoID) {
        Message
        Status
    }
}`

export const GetParticipant = `query MyQuery($ParticipantID: Int!) {
    getParticipant(ParticipantID: $ParticipantID) {
        FirstName
        LastName
        NickName
        Birthday
        Country
        UserID
        CreatedAt
        UpdatedAt
        ParticipantID
        IDImageOne {
            bucket
            region
            key
            order
        }
        IDImageTwo {
            bucket
            region
            key
            order
        }
    }
}`

export const UpdateParticipant = `mutation MyMutation ($FirstName: String, $LastName: String, $NickName: String, $Birthday: String, $Country: String, $IDImageOne: S3Object, $IDImageTwo: S3Object, $ParticipantID: Int) {
    updateParticipant(input: {FirstName: $FirstName, LastName: $LastName, NickName: $NickName, Birthday: $Birthday, Country: $Country, IDImageOne: $IDImageOne, IDImageTwo: $IDImageTwo, ParticipantID: $ParticipantID}) {
        FirstName
        LastName
        NickName
        Birthday
        Country
        IDImageOne {
            bucket
            key
            region
        }
        IDImageTwo {
            bucket
            key
            region
        }
        ParticipantID
        CreatedAt
        UpdatedAt
    }
}`

export const CreateParticipant = `mutation MyMutation ($FirstName: String, $LastName: String, $NickName: String, $Birthday: String, $Country: String, $IDImageOne: S3Object, $IDImageTwo: S3Object) {
    createParticipant(input: {FirstName: $FirstName, LastName: $LastName, NickName: $NickName, Birthday: $Birthday, Country: $Country, IDImageOne: $IDImageOne, IDImageTwo: $IDImageTwo}) {
        ParticipantID
        FirstName
        LastName
        NickName
        Birthday
        Country
        IDImageOne {
            bucket
            key
            region
        }
        IDImageTwo {
            bucket
            key
            region
        }
    }
}`

export const CreateVideoAdmin = `mutation MyMutation ($FirstName: String, $LastName: String, $NickName: String, $Birthday: String, $Country: String, $IDImageOne: S3Object, $IDImageTwo: S3Object, $Participants: [String] $VideoTitle: String, $VideoDescription: String, $VideoFilm: String, $VideoIntro: S3Object, $VideoReal: S3Object, $VideoDuration: Int, $Stills: [S3Object], $Status: Int, $Tags: [TagItem], $MarqueeTags: [Int], $UserID: Int!, $PublishedAt: String) {
    createVideoAdmin(input: {FirstName: $FirstName, LastName: $LastName, NickName: $NickName, Birthday: $Birthday, Country: $Country, IDImageOne: $IDImageOne, IDImageTwo: $IDImageTwo, Participants: $Participants, VideoTitle: $VideoTitle, VideoDescription: $VideoDescription, VideoFilm: $VideoFilm, VideoIntro: $VideoIntro, VideoReal: $VideoReal, VideoDuration: $VideoDuration, Stills: $Stills, Status: $Status, Tags: $Tags, MarqueeTags: $MarqueeTags, UserID: $UserID, PublishedAt: $PublishedAt}) {
      Message
      Status
    }
}`

export const UpdateVideo = `mutation MyMutation ($VideoID: Int!, $FirstName: String, $LastName: String, $NickName: String, $Birthday: String, $Country: String, $IDImageOne: S3Object, $IDImageTwo: S3Object, $Participants: [String] $VideoTitle: String, $VideoDescription: String, $VideoFilm: String, $VideoIntro: S3Object, $VideoReal: S3Object, $VideoDuration: Int, $Stills: [S3Object], $Status: Int, $Tags: [TagItem], $MarqueeTags: [Int], $UserID: Int!, $PublishedAt: String) {
    updateVideoAdmin(input: {VideoID: $VideoID, FirstName: $FirstName, LastName: $LastName, NickName: $NickName, Birthday: $Birthday, Country: $Country, IDImageOne: $IDImageOne, IDImageTwo: $IDImageTwo, Participants: $Participants, VideoTitle: $VideoTitle, VideoDescription: $VideoDescription, VideoFilm: $VideoFilm, VideoIntro: $VideoIntro, VideoReal: $VideoReal, VideoDuration: $VideoDuration, Stills: $Stills, Status: $Status, Tags: $Tags, MarqueeTags: $MarqueeTags, UserID: $UserID, PublishedAt: $PublishedAt}) {
      Message
      Status
    }
}`

export const GetMarqueeTags = `query MyQuery {
    getMarqueeTagList(input: {NumberItem: 1000}) {
        CountAll
        NumberItem
        Page
        FilterText
        Items {
            MarqueeTagID
            Name
            Slug
        }
    }
}`

export const GetVideoAdmin = `query MyQuery($VideoID: Int!) {
    getVideoAdmin(VideoID: $VideoID) {
        UserID
        FirstName
        LastName
        NickName
        Birthday
        Country
        VideoTitle
        VideoDescription
        VideoFilm
        VideoDuration
        Status
        CognitoUserID
        PublishedAt
        CreatedAt
        UpdatedAt
        IDImageOne {
            bucket
            region
            key
            order
        }
        IDImageTwo {
            bucket
            region
            key
            order
        }
        MarqueeTags {
            VideoMarqueeTagID
            MarqueeTagID
            Name
            Slug
        }
        Participants
        Stills {
            bucket
            region
            key
            order
        }
        Tags {
            VideoTagID
            TagID
            Name
            Slug
        }
        VideoID
        VideoIntro {
            bucket
            region
            key
            order
        }
        VideoReal {
            bucket
            region
            key
            order
        }
    }
}`

export const GetTags = `query MyQuery {
    getTagListAdmin(input: {NumberItem: 100}) {
        CountAll
        NumberItem
        Page
        FilterText
        Items {
            TagID
            Slug
            Name
        }
    }
}`