import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { API, graphqlOperation } from 'aws-amplify';
import { DeleteVideoInTrash, MoveVideoToTrash } from '../../lib/graphql-command/videos-command';
import { ButtonTemplate } from '../button/button-template';

const useStyle = makeStyles(theme => ({
    title: {
        padding: '0 5px'
    },
    closeButton: {
        position: 'absolute',
        color: theme.palette.grey[500]
    },
}))


export const DeleteVideoDialog = ({open, setOpen, video, status, getListVideo}) => {
    const [disabled, setDisabled] = useState(false);

    const handleDelete = () => {
        setDisabled(true)
        const endPoint = status === 5 ? DeleteVideoInTrash : MoveVideoToTrash
        API.graphql(graphqlOperation(endPoint, {VideoID: video.VideoID}))
        .then(result => {
            setDisabled(false)
            setOpen(false)
            getListVideo();

        })
        .catch(err => console.log(err))
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle className={useStyle().title}>
                <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon className={useStyle().closeButton} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box py={5} textAlign='center'>
                    Are you sure?
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justify='center' spacing={5}>
                    <Grid item>
                        <Box pb={5}>
                            <ButtonTemplate
                                variant='outlined'
                                color='primary'
                                size='small'
                                changetextcolor='#fff'
                                borderradius='5px'
                                onClick={() => setOpen(false)}
                            >Cancel</ButtonTemplate>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pb={5}>
                            <ButtonTemplate
                                variant='contained'
                                color='primary'
                                size='small'
                                changetextcolor='#fff'
                                borderradius='5px'
                                onClick={handleDelete}
                                disabled={disabled}
                            >{status === 5 ? 'Delete permanently' : 'Move to trash'}</ButtonTemplate>
                        </Box>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}